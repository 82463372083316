
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Vendors
import FullCalendar, {Calendar, CalendarOptions} from "@fullcalendar/vue3";
import hrLocale from '@fullcalendar/core/locales/hr';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

// Models
import {AppCoachMatchesRootListIndex} from "@/models/app/coach/matches/root/list/index";
import {AppCoachTrainingsRootListIndex} from "@/models/app/coach/trainings/root/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
  ElButton,
  ElMessageBox,
} from "element-plus";
import {
  ArrowLeft,
  ArrowRight,
} from "@element-plus/icons-vue";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachDashboardNavigationVue from "@/components/coach/dashboard/navigation.vue";
import AppCoachDashboardRootUserInformationIndexVue from "@/views/app/coach/dashboard/root/user-information/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    ElButton,
    FullCalendar,
    ArrowLeft,
    ArrowRight,
    CoachNavigationVue,
    CoachDashboardNavigationVue,
    AppCoachDashboardRootUserInformationIndexVue,
  },
})
export default class AppCoachDashboardCalendarIndexVue extends Vue {
  isLoading: boolean | null = false;
  formDataMatches: any = {
    filter: {
      user_id: "*",
      match_category_id: "*",
      date: [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };
  formDataTrainings: any = {
    filter: {
      user_id: "*",
      training_category_id: "*",
      date: [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };
  moment: any = null;

  calendar: any = null;
  calendarApi: any = null;
  calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      interactionPlugin
    ],
    locale: hrLocale,
    headerToolbar: {
      left: "",
      center: "title",
      right: "",
    },
    initialView: "dayGridMonth",
    events: [],
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
    },
    editable: false,
    selectable: false,
    selectMirror: false,
    dayMaxEvents: false,
    weekends: true,
    eventClick: this.getEvent,
    // : this.handleMonthChange
  };

  matches: Array<AppCoachMatchesRootListIndex> = [];
  trainings: Array<AppCoachTrainingsRootListIndex> = [];

  $refs!: {
    Calendar: any;
  };

  async prevMonth(): Promise<void> {
    this.calendar.getApi().prev();

    this.formDataMatches.filter.date = [
      moment(this.calendar.getApi().getDate()).startOf("month").format('DD-MM-YYYY'),
      moment(this.calendar.getApi().getDate()).endOf("month").format('DD-MM-YYYY'),
    ];

    this.formDataTrainings.filter.date = [
      moment(this.calendar.getApi().getDate()).startOf("month").format('DD-MM-YYYY'),
      moment(this.calendar.getApi().getDate()).endOf("month").format('DD-MM-YYYY'),
    ];

    await this.getMatches();
    await this.getTrainings();

    this.setEvents();
  }

  async nextMonth(): Promise<void> {
    this.calendar.getApi().next();

    this.formDataMatches.filter.date = [
      moment(this.calendar.getApi().getDate()).startOf("month").format('DD-MM-YYYY'),
      moment(this.calendar.getApi().getDate()).endOf("month").format('DD-MM-YYYY'),
    ];

    this.formDataTrainings.filter.date = [
      moment(this.calendar.getApi().getDate()).startOf("month").format('DD-MM-YYYY'),
      moment(this.calendar.getApi().getDate()).endOf("month").format('DD-MM-YYYY'),
    ];

    await this.getMatches();
    await this.getTrainings();

    this.setEvents();
  }

  async getMatches(): Promise<void> {
    await getRequest({
      uri: `/common/matches`,
      formData: this.formDataMatches,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matches = r.data;
      }
    });
    this.isLoading = false;
  }

  async getTrainings(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/trainings`,
      formData: this.formDataTrainings,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.trainings = r.data;
      }
    });
    this.isLoading = false;
  }

  setEvents(): void {
    const events: Array<any> = [];
    this.calendarOptions.events = [];

    this.matches.forEach((item: AppCoachMatchesRootListIndex) => {
      events.push({
        id: item.id,
        title: `Utakmica - ${item.user.content.name} ${item.user.content.surname} (${item.match_category.content.name})`,
        start: item.content.date,
        classNames: "fc-match",
        extendedProps: {
          id: item.id,
          type: "Utakmica",
          category: item.match_category.content.name,
          start: moment(item.content.date).format("YYYY-MM-DD HH:mm"),
          user: `${item.user.content.name} ${item.user.content.surname}`
        },
      });
    });

    this.trainings.forEach((item: AppCoachTrainingsRootListIndex) => {
      events.push({
        id: item.id,
        title: `Trening - ${item.user.content.name} ${item.user.content.surname} (${item.training_category.content.name})`,
        start: moment(item.content.date).format("YYYY-MM-DD HH:mm"),
        classNames: "fc-training",
        extendedProps: {
          id: item.id,
          type: "Trening",
          category: item.training_category.content.name,
          start: moment(item.content.date).format("YYYY-MM-DD HH:mm"),
          user: `${item.user.content.name} ${item.user.content.surname}`
        },
      });
    });

    this.calendarOptions.events = events;
  }

  getEvent(e): void {
    ElMessageBox.alert(
        `<div class="title">${e.event._def.extendedProps.user}</div>
         <div class="information">Kategorija: ${e.event._def.extendedProps.category}</div>
         <div class="information">Vrijeme: ${moment(e.event._def.extendedProps.start).format("DD.MM.YYYY. - HH:mm")}h</div>`,
        e.event._def.extendedProps.type,
        {
          dangerouslyUseHTMLString: true,
        }
    )
  }

  async created(): Promise<void> {
    this.moment = moment;

    await this.getMatches();
    await this.getTrainings();

    this.setEvents();
  }

  async mounted(): Promise<void> {
    this.calendar = this.$refs.Calendar;
  }
}
